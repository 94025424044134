var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "company-select-list d-flex flex-column justify-content-center",
    },
    _vm._l(_vm.companies, function (company) {
      return _c("div", { key: company.id }, [
        _c(
          "div",
          {
            staticClass: "company-container",
            class: _vm.updateAvailable(company) ? "update-available" : "",
            on: {
              click: function ($event) {
                return _vm.$emit("company-selected", company)
              },
            },
          },
          [
            _vm.updateAvailable(company)
              ? _c("feather-icon", {
                  staticClass: "alert-circle update-available",
                  attrs: { type: "alert-circle" },
                })
              : _vm._e(),
            _c("div", [
              _vm._v("\n        " + _vm._s(company.name) + "\n      "),
            ]),
            _c(
              "div",
              { staticClass: "arrow-container" },
              [
                _c("fa-icon", {
                  staticClass: "fa-md arrow",
                  attrs: { icon: "chevron-right" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }